import uaaAxios from "@/utils/authAxios";
import Vue from "vue";

export default {
  saveStage(frm) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/saveStage`, frm)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  saveActivityWithStageId(requestActivity) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/saveActivityWithStageId`, requestActivity)
        .then((response) => {
          resolve(Vue._.post(response, "data"));
        })
        .catch((err) => reject(err))
        .finally(() => {
          // Servicio que trae la tabla de actividades.
        });
    });
  },
  saveTrack(request) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/saveTrack`, request)
        .then((response) => {
          resolve(Vue._.post(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  updateTrack(request) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/updateTrack`, request)
        .then((response) => {
          resolve(Vue._.post(response, "data"));
        })
        .catch((err) => reject(err));
    });
  },
  deleteStage: function (stageId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${process.env.VUE_APP_PMO_API}/plan/deleteStage/${stageId}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteActivity: function (id) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${process.env.VUE_APP_PMO_API}/plan/deleteActivity/${id}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getProjectStages: function (projectId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getProjectStages/${projectId}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getAccomplishmentTable: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getAccomplishmentTable`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getActivitiesByStage: function (stageId) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getActivitiesByStage/${stageId}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getComboResponsible: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/admin/getComboResponsible`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartSummary: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getChartSummary`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getActivities: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getActivities`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getPercentages: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/admin/getPercentages`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getStagesForCombo: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getStagesForCombo`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getStagesTableConfiguration: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getStagesTableConfiguration`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getActivitiesTableConfiguration: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getActivitiesTableConfiguration`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getComboActivitiesByStage: function (idStage) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${process.env.VUE_APP_PMO_API}/plan/getComboActivitiesByStage/${idStage}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getTrackTable: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getTrackTable`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteTrackActivityPlanification: function (idActivityTrackPlanification) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${process.env.VUE_APP_PMO_API}/plan/deleteTrackActivityPlanification/${idActivityTrackPlanification}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartTrackByMonth: function (project) {
    return new Promise((resolve, reject) => {
      const json = JSON.stringify(project);
      uaaAxios
        .post(`${process.env.VUE_APP_PMO_API}/plan/getChartTrackByMonth`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });

    /*
    const data = [
      {
        // Actividad 1
        name: "Cambio Plan Prepago Mono Línea",
        data: [
          {
            // Etapa 1
            x: "Cambio Plan Móvil",
            y: [
              // Fechas Planificadas
              new Date("2022-02-01").getTime(),
              new Date("2022-03-11").getTime(),
            ],
            goals: [
              {
                // Fecha Actual
                name: "Break",
                value: new Date("2022-05-07").getTime(),
                strokeColor: "#CD2F2A",
              },
            ],
          },
          {
            x: "Cambio Plan Móvil",
            y: [
              // Fechas Reales
              new Date("2022-02-07").getTime(),
              new Date("2022-03-10").getTime(),
            ],
          },
        ],
      },
    ];
    return data;
    */
  },
};
