import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getChartInvestment: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartInvestment`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartScalability: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartScalability`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartAgileMethodology: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartAgileMethodology`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartOperationReduction: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartOperationReduction`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartTalent: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartTalent`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartDigitalChannel: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getChartDigitalChannel`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  saveFixedData: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/saveProductivity`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getFixedData: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getProductivity`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveProductivityTrace: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/saveProductivityTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getProductivityTrace: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/getProductivityTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProductivity: function (project, idProductivity) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/productivity/deleteProductivity?idProductivity=${idProductivity}`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
