import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getSummaryKPINegocio: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartSumaryKPINegocio`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getHistoryKPINegocio: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartHistoryKPINegocio`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getRatioHistoryKPINegocio: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartRatioKPINegocio`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getCompositionKPINegocio: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartCompositionKPINegocio`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  getCostEffectiveness: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartCostEffectiveness`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getCostReduction: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartCostReduction`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getExternalCustomer: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartExternalCustomer`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getInternalCustomer: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartInternalCustomer`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getTakeDecisions: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartTakeDecisions`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getFirstLine: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/chartFirstLine`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartKpiDash: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/getChartKpiDash`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  addSatisfactionTrace: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/addSatisfactionTrace`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getIndexMaxSatisfaction: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/getIndexMaxSatisfaction`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getSatisfaction: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/getSatisfaction`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteSatisfaction: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/deleteSatisfaction`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  saveResolutionRate: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/saveResolution`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getResolutionRate: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/kpis/getResolution`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
