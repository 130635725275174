import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getChartResolutionRate: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartResolutionRate`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartTimeDeviation: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartTimeDeviation`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartHourToResolve: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartHourToResolve`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartPotentialClientsLots: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartPotentialClientsLots`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartQuickResponses: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartQuickResponse`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartClientRotation: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartClientRotation`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartCriticalRiskEvents: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartCriticalRiskEvents`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartIncorporation: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartIncorporation`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartYield: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartYield`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartRotation: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartRotation`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartToolsAvailability: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartToolsAvailability`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartTechAndSystemTraining: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/chartDigitalCapabilities`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveFixedData: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/saveFixedData`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getFixedData: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/getFixedData`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  savePerformanceTrace: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/saveDataTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getPerformanceTrace: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/getPerformanceTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deletePerformanceTrace: function (project, idPerformance) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/performance/deletePerformanceTrace?idPerformance=${idPerformance}`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
