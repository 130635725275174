import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;
export default {
  saveProject: function (request) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/project/saveProject`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getProject: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/project/getProject`, project)
        .then((response) => {
          resolve(response.data);
          //console.log(response);
          //resolve(getSummaryObjectives);
        })
        .catch((err) => reject(err));
    });
  },
  getProjects: function (enterprise) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/project/getProjectsByEnterpriseId`, enterprise)
        .then((response) => {
          resolve(response.data);
          //console.log(response);
          //resolve(getSummaryObjectives);
        })
        .catch((err) => reject(err));
    });
  },
  getTeam: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/team/getTeam`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveTeam: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/team/saveTeam`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteTeam: function (idResponsible) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${apiURL}/team/deleteTeam?idResponsible=${idResponsible}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
