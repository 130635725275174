<template>
  <div class="div-container">
    <!-- NEW -->
    <!-- Ficha Proyecto -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Ficha Proyecto</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Ficha')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="10">
              <!-- <div style="display: flex; flex-direction: column"> -->
              <div class="ficha-text">
                <div style="color: #333333; font-size: 18px">
                  <span>• Nombre Proyecto: </span>
                  <span> {{ projectFile.name }} </span>
                </div>
                <div style="color: #333333; font-size: 18px">
                  <span>• Responsable Proyecto: </span>
                  <span> {{ projectFile.responsible }} </span>
                </div>
                <div style="color: #333333; font-size: 18px">
                  <span>• Objetivo del Negocio: </span>
                  <span> {{ projectFile.businessObjective }} </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- ROEADMAP & BUDGET & RISK -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span style="font-weight: 600; font-size: 23px" class="tway-violet--text">Indicadores de Proyecto</span>
      </v-col>
      <!-- Roadmap -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Roadmap</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Roadmap')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="mt-8 box-content">
            <v-row class="box-container">
              <v-col class="pa-0 ma-0 inside-box">
                <div class="text-center mt-2">
                  <p style="font-size: 20px">
                    Término Planificado:
                    <span style="font-weight: normal">
                      {{ summaryPlanification.globalRealEndDatePlanificatedSummary }}
                    </span>
                  </p>
                </div>
                <div></div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 box-container height-fix">
              <v-col class="pa-0 ma-0 inside-box">
                <div class="text-center">
                  <p style="font-size: 16px">Avance Real</p>
                </div>
                <div>
                  <VueApexCharts
                    :series="chartSummary.series"
                    :options="chartSummary.options"
                    height="220"
                    type="radialBar"
                  />
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 inside-box" align="center" justify-self="center">
                <div class="text-center fix-text">
                  <p style="font-size: 16px">Desviación</p>
                </div>
                <div class="text-veritical-align">
                  <span
                    v-if="summaryPlanification.deviationPercentageProgressNumber < 0"
                    style="color: #d50000; font-size: 45px"
                    >{{ summaryPlanification.deviationPercentageProgress }}</span
                  >
                  <span
                    v-if="summaryPlanification.deviationPercentageProgressNumber >= 0"
                    style="color: #16c62e; font-size: 45px"
                    >{{ summaryPlanification.deviationPercentageProgress }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
      <!-- Budget -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Presupuesto</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Presupuesto')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="mt-8 box-content">
            <v-row class="box-container">
              <v-col cols="12" class="pa-0 ma-0 inside-box">
                <div class="text-center">
                  <div class="text-center mt-2">
                    <span style="color: #333333; font-size: 20px">Gasto Actual: </span>
                    <span
                      v-if="budgetData.monetaryUnit != 'UF'"
                      class="mb-2"
                      style="color: #333333; font-size: 20px; font-weight: normal"
                      >{{ budgetData.monetaryUnit + " " + budgetData.currentExpense }}</span
                    >
                    <span
                      v-if="budgetData.monetaryUnit == 'UF'"
                      class="mb-2"
                      style="color: #333333; font-size: 20px; font-weight: normal"
                      >{{ budgetData.currentExpense + " " + budgetData.monetaryUnit }}</span
                    >
                  </div>
                  <div class="text-center">
                    <span style="color: #333333; font-size: 20px; font-weight: normal">Presupuesto Total: </span>
                    <span
                      v-if="budgetData.monetaryUnit != 'UF'"
                      class="mb-2"
                      style="color: #333333; font-size: 20px; font-weight: normal"
                      >{{ budgetData.monetaryUnit + " " + budgetData.totalBudget }}</span
                    >
                    <span
                      v-if="budgetData.monetaryUnit == 'UF'"
                      class="mb-2"
                      style="color: #333333; font-size: 16px; font-weight: normal"
                      >{{ budgetData.totalBudget + " " + budgetData.monetaryUnit }}</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 box-container height-fix">
              <v-col class="pa-0 ma-0 inside-box" align="center">
                <div>
                  <p style="color: #333333; font-size: 16px">Desviación Según Planeado</p>
                </div>
                <div class="text-veritical-align">
                  <span style="color: #7319d5; font-size: 45px; font-weight: normal; margin-top: 50%"
                    >{{ budgetData.deviation }}%</span
                  >
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 inside-box" align="center" justify-self="center">
                <div class="tway-violet--text text-center">
                  <span style="color: #333333; font-size: 16px"> Gasto Actual</span>
                </div>
                <div class="mt-12">
                  <VueApexCharts
                    :series="chartExpense.series"
                    :options="chartExpense.chartOptions"
                    height="190"
                    type="radialBar"
                  />
                </div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
      <!-- Risk -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Riesgo</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Riesgo')"
            >
              <!-- <v-icon color="purple"> mdi-cog-outline </v-icon> -->
              <!-- <a href="/pmo-digital/planification">VER INDICADORES</a> -->
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" align="center" justify-self="center">
                <div class="mt-4" style="display: flex; flex-direction: column">
                  <span style="color: #333333; font-size: 20px">{{ riskDimension }}:</span>
                  <div v-for="item in riskData" :key="item" class="d-flex column">
                    <div
                      v-if="item.risk_classification == 'red'"
                      class="classification-color"
                      :style="'background-color:' + item.risk_classification"
                    ></div>
                    <span
                      v-if="item.risk_classification == 'red'"
                      class="mb-2 ms-2"
                      style="color: #333333; font-size: 16px; font-weight: normal"
                    >
                      {{ item.risk }}
                    </span>
                    <span
                      v-if="item.risk_classification != 'red'"
                      class="mb-2 ms-2"
                      style="color: #333333; font-size: 16px; font-weight: normal"
                    >
                    </span>
                  </div>
                </div>
              </v-col>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 mt-2" align="center" justify-self="center">
              <v-col class="pa-0 ma-0 text-center" align-self="center" justify-self="center">
                <div v-if="riskResult == 'hr'">
                  <img src="../../assets/img/pmo/img/high-risk.png" width="100%" alt="" />
                </div>
                <div v-else-if="riskResult == 'mr'">
                  <img src="../../assets/img/pmo/img/medium-risk.png" width="100%" alt="" />
                </div>
                <div v-else-if="riskResult == 'lr'">
                  <img src="../../assets/img/pmo/img/low-risk.png" width="100%" alt="" />
                </div>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- OKR & PROFIT -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span style="font-weight: 600; font-size: 23px" class="tway-violet--text"> Indicadores de Negocio</span>
      </v-col>
      <!-- Okr -->
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">OKR</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('OKR')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="12">
              <div class="mt-3" style="display: flex; flex-direction: row; justify-content: center">
                <p style="color: white">..</p>
                <span v-if="date.start" style="color: #333333; font-size: 20px; font-weight: normal">
                  {{ date.start + " al " + date.end }}</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 mt-3 box-container">
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title1 }}
                  </p>
                </div>
                <VueApexCharts
                  ref="chartOkr1"
                  :series="chartOkr1.series"
                  :options="chartOkr1.options"
                  :labels="chartOkr1.options.labels"
                  height="280"
                  type="pie"
                />
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title2 }}
                  </p>
                </div>
                <VueApexCharts
                  ref="chartOkr2"
                  :series="chartOkr2.series"
                  :options="chartOkr2.options"
                  :labels="chartOkr2.options.labels"
                  height="280"
                  type="pie"
                />
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title3 }}
                  </p>
                </div>
                <VueApexCharts
                  ref="chartOkr3"
                  :series="chartOkr3.series"
                  :options="chartOkr3.options"
                  :labels="chartOkr3.options.labels"
                  height="280"
                  type="pie"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Rentabilidad -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Rentabilidad</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Rentabilidad')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <div class="text-center mt-3">
              <span style="color: #333333; font-size: 20px">Operaciones </span>
            </div>
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Digitalización de Proceso
                    <ToolTips
                      width="250"
                      description="Cantidad de procesos, funciones y operaciones comerciales en una empresa, que se optimizan al gestionarlas en canales digitales o con herramientas digitales"
                    />
                  </p>
                </div>
                <VueApexCharts
                  :series="chartDigitizationProcess.series"
                  :options="chartDigitizationProcess.chartOptions"
                  height="190"
                  type="radialBar"
                />
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Optimización
                    <ToolTips
                      width="250"
                      description="Ratio de ganancias o pérdidas obtenidas sobre lo invertido producto de mejoras en logísitca, atención al clientes, modernizaciones, capacitaciones, productividad, etc."
                    />
                  </p>
                </div>
                <VueApexCharts
                  :series="chartOpimization.series"
                  :options="chartOpimization.chartOptions"
                  height="190"
                  type="radialBar"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- AGILITY & EFFICIENCY -->
    <v-row class="pa-0 ma-0">
      <!-- Agility -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Agilidad</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Agilidad')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row style="display: flex; flex-direction: row; align-content: space-between; height: 80%">
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div>
                <VueApexCharts
                  ref="chartAgility"
                  v-if="chartAgility.series.length > 0"
                  type="radar"
                  height="390"
                  :series="chartAgility.series"
                  :options="chartAgility.options"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Efficiency -->
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Eficiencia</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Eficiencia')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="mt-7" style="display: flex; flex-direction: row; align-content: space-between">
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div class="text-center">
                <span style="color: #333333; font-size: 20px">Herramientas Digitales </span>
              </div>
              <div class="text-center mt-4">
                <p style="font-size: 16px">
                  Reducción de Costos en Procesos
                  <ToolTips
                    width="300"
                    description="Disminución en el costo de
                  produción de una unidad de producto en un periodo de tiempo
                  por el uso de herramientas digitales."
                  />
                </p>
              </div>
              <div>
                <VueApexCharts
                  ref="chartCostReduction"
                  :series="chartCostReduction.series"
                  :options="chartCostReduction.options"
                  width="290"
                  height="300"
                  type="bar"
                />
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <span style="color: #333333; font-size: 20px">Canales Digitales </span>
                </div>
                <div class="text-center mt-4">
                  <p style="font-size: 16px">
                    Tasa de Venta
                    <ToolTips width="300" description="Cantidad de usuarios que realiza una compra online." />
                  </p>
                </div>
                <VueApexCharts
                  ref="chartDigitalSale"
                  :series="chartDigitalSale.series"
                  :options="chartDigitalSale.options"
                  width="290"
                  height="300"
                  type="area"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- ENGAGEMENT & KPI -->
    <v-row class="pa-0 ma-0">
      <!-- Engagement -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Engagement</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Engagement')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <!-- transformación digital -->
            <div class="text-center mt-4">
              <span style="color: white; font-size: 20px">Talento Digital </span>
            </div>
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div>
                  <div class="text-center">
                    <p style="font-size: 16px">
                      Adopción Tecnologías
                      <ToolTips
                        width="250"
                        description="Uso y adherencia a tecnologías, herramientas, plataformas o funcionalidades específicas por parte de usuarios en el tiempo."
                      />
                    </p>
                  </div>
                  <VueApexCharts
                    :series="chartTechnologyAdoption.series"
                    :options="chartTechnologyAdoption.options"
                    height="190"
                    type="radialBar"
                  />
                </div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <div>
                    <p style="font-size: 16px">Tasa de Conversión</p>
                  </div>
                  <div>
                    <VueApexCharts
                      :series="chartCircleConversionRate.series"
                      :options="chartCircleConversionRate.options"
                      height="190"
                      type="radialBar"
                    />
                  </div>
                </div>
              </div>
            </v-col>
            <!-- ltv -->
            <v-col cols="6" class="pa-0 ma-0" align="center" justify-self="center" style="display: none">
              <v-col cols="12" class="pa-0 ma-0" align="center" justify-self="center">
                <h3 class="text-center" style="font-size: 20px; font-weight: normal">Valor de la Vida del Cliente</h3>
              </v-col>
              <v-col class="pa-0 ma-0">
                <div style="display: flex; flex-direction: column">
                  <div class="text-sum" style="font-size: 15px">
                    Total LTV mes anterior (1):
                    {{ budgetData.monetaryUnit + ltvSum.lastMonthLTV }}
                  </div>
                  <div class="text-sum" style="font-size: 15px">
                    Total LTV mes actual (0):
                    {{ budgetData.monetaryUnit + ltvSum.thisMonthLTV }}
                  </div>
                  <div>
                    <VueApexCharts
                      type="heatmap"
                      width="305"
                      height="300"
                      :series="chartDigitalClient.series"
                      :options="chartDigitalClient.options"
                    />
                  </div>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Kpis -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Satisfacción</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Satisfacción')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row
            class="pa-0 ma-0 mt-7"
            style="display: flex; flex-direction: column; align-content: space-between; height: 90%; width: 100%"
          >
            <v-row class="pa-0 ma-0" style="width: 100%">
              <v-col class="pa-0 ma-0">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span style="color: #333333; font-size: 22px"
                    >NPS
                    <ToolTips
                      width="250"
                      description="Net Promotor Score es un indicador de Satisfacción."
                      formula="%Promotores - %Detractores"
                    />:
                  </span>
                  <p style="color: white">..</p>
                  <span v-if="npsValue == 0" style="color: #333333; font-size: 24px">{{ npsValue }} </span>
                  <span v-if="npsValue < 0" style="color: #d50000; font-size: 24px">{{ npsValue }} </span>
                  <span v-if="npsValue > 0" style="color: #16c62e; font-size: 24px">{{ npsValue }} </span>
                  <img
                    v-if="npsValue > 0"
                    class="mt-1"
                    width="20px"
                    height="20px"
                    :src="require('@/assets/img/pmo/up-arrow-green.svg')"
                  />
                  <img
                    v-if="npsValue < 0"
                    class="mt-1"
                    width="20px"
                    height="20px"
                    :src="require('@/assets/img/pmo/down-arrow-red.svg')"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 mt-3" style="width: 100%">
              <v-col class="pa-0 ma-0" align="center" justify-self="center">
                <p style="font-size: 16px">
                  Tasa de Resolución
                  <ToolTips
                    width="370"
                    description="Autoservicio: cualquier plataforma de auto atención, que le permite al usuario resolverse sus incidencias."
                    description2="N1: servicio de primer contacto con el cliente."
                    description3="N2: primer nivel de escalamiento para la resolución de incidencias."
                    description4="N3: segundo nivel de escalamiento hacia especialistas para resolución de incidencias."
                  />
                </p>
                <div class="text-center">
                  <VueApexCharts
                    v-if="firstLine.series.length > 0"
                    :series="firstLine.series"
                    :options="firstLine.options"
                    height="350"
                    type="pie"
                  />
                </div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
      <!-- Performance -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Desempeño</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Desempeño')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <div class="text-center mt-4">
              <span style="color: #333333; font-size: 20px">Talento Digital </span>
            </div>
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Rendimiento
                    <ToolTips
                      width="370"
                      description="Mide de forma periódica, cuantitativamente y cualitativamente, la forma en que un talento con competencias digitales cumple tareas y funciones encomendadas para lograr su éxito."
                    />
                  </p>
                </div>
                <VueApexCharts
                  :series="chartYield.series"
                  :options="chartYield.chartOptions"
                  height="190"
                  type="radialBar"
                />
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Mejora en Habilidades Digitales
                    <ToolTips
                      width="370"
                      description="Aumento en el nivel de dominio de habilidades digitales de trabajadores en una empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  :series="chartDigitalSkillsImprovement.series"
                  :options="chartDigitalSkillsImprovement.chartOptions"
                  height="190"
                  type="radialBar"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- PERFORMANCE & PRODUCTIVITY -->
    <v-row class="pa-0 ma-0">
      <!-- Productivity -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Productividad</strong>
            <v-btn
              class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
              color="coral lighten-2"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="redirectPage('Productividad')"
            >
              VER INDICADOR
            </v-btn>
          </v-row>
          <v-row class="pa-0 ma-0 mt-7 box-container">
            <!-- ROI -->
            <v-col class="pa-0 ma-0">
              <div style="display: flex; flex-direction: column" align="center" justify-self="center">
                <div class="text-center">
                  <p style="font-size: 16px">
                    Retorno de la Inversión
                    <ToolTips
                      width="370"
                      description="Beneficio final que recibirá una empresa como resultado de invertir cierta cantidad de recursos en proyectos orientados a aumentar la productividad."
                    />
                  </p>
                </div>
                <div>
                  <VueApexCharts
                    ref="chartInvestment"
                    :series="chartInvestment.series"
                    :options="chartInvestment.options"
                    width="400"
                    height="300"
                    type="area"
                  />
                </div>
              </div>
            </v-col>
            <!-- Escalabilidad -->
            <v-col
              cols="6"
              class="pa-0 ma-0"
              style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start"
            >
              <div class="pa-0 ma-0" style="width: 100%; height: fit-content">
                <h3 class="text-center" style="font-size: 20px; font-weight: normal">Escalabilidad</h3>
              </div>

              <div class="pa-0 ma-0" style="width: 50%">
                <div style="display: flex; flex-direction: column">
                  <div class="text-center">
                    <p style="font-size: 16px">
                      Servicio
                      <ToolTips
                        width="370"
                        description="Capacidad deseable de un sistema o una red para reaccionar, adaptarse o crecer, sin perder calidad en los servicios ofrecidos."
                      />
                    </p>
                  </div>
                  <div>
                    <VueApexCharts
                      :series="chartService.series"
                      :options="chartService.chartOptions"
                      height="220"
                      type="radialBar"
                    />
                  </div>
                </div>
              </div>
              <div class="pa-0 ma-0" style="width: 50%">
                <div style="display: flex; flex-direction: column">
                  <div>
                    <div class="text-center">
                      <p style="font-size: 16px">
                        Proceso
                        <ToolTips
                          width="370"
                          description="Habilidad deseable de un proceso para reaccionar, adaptarse o crecer en magnitud, sin perder eficiencia y productividad."
                        />
                      </p>
                    </div>
                    <VueApexCharts
                      :series="chartProcess.series"
                      :options="chartProcess.chartOptions"
                      height="220"
                      type="radialBar"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import callForTenderService from "@/services/callForTenderService";
import ToolTips from "../../components/pmo/utils/ToolTips";
import projectService from "../../services/pmo/project/projectService";
import PlanificationService from "../../services/pmo/planification/planificationService";
import BudgetService from "@/services/pmo/budget/budgetService";
import RiskService from "@/services/pmo/risk/riskService";
import ObjectivesService from "@/services/pmo/objectives/objectivesService";
import EfficiencyService from "../../services/pmo/efficiency/efficiencyService";
import EngagementService from "../../services/pmo/engagement/engagementService";
import KpiBusinessService from "@/services/pmo/kpi/kpiBusinessService";
import productivityService from "../../services/pmo/productivity/productivityService";
import PerformanceService from "@/services/pmo/performance/performanceService";
import AgilityService from "../../services/pmo/agility/organizationalAgilityService";
import ProfitabilityService from "../../services/pmo/profitability/profitabilityService";
import LtvService from "../../services/pmo/ltv/ltvService";
import VueApexCharts from "vue-apexcharts";
//import PolarArea from "../../components/charts/pmo/apex/polar-area/PolarArea";

export default {
  name: "Dashboard",
  components: {
    VueApexCharts,
    ToolTips,
    //PolarArea,
  },
  data() {
    return {
      full: 100,
      projects: [
        {
          id: 1,
          name: "Canal de Ventas Online",
          priority: "High",
          status: "In process",
          complete: "25%",
        },
        {
          id: 2,
          name: "Rediseño Servicio Clientes",
          priority: "Normal",
          status: "In process",
          complete: "25%",
        },
        {
          id: 3,
          name: "Implentación Safe",
          priority: "High",
          status: "In process",
          complete: "35%",
        },
        {
          id: 4,
          name: "Model Engagment",
          priority: "Low",
          status: "Not Started",
          complete: "0%",
        },
      ],
      projectIds: {
        enterpriseId: this.$route.params.enterpriseId ? this.$route.params.enterpriseId : "1",
        projectId: this.$route.params.projectId ? this.$route.params.projectId : "1",
      },
      // Datos generales del proyecto
      projectFile: [],
      //ROADMAP
      summaryPlanification: [],
      chartSummary: {
        series: [],
        options: {
          chart: {
            height: 350,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            colors: ["#ffffff"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            colors: ["#7319d5"],
            type: "solid",
          },
          stroke: { lineCap: "round" },
          labels: [""],
        },
      },
      chartTrace: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 650,
            width: "100%",
            type: "rangeBar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "100%",
            },
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              show: false,
              align: "left",
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 400,
                cssClass: "apexcharts-yaxis-label",
              },
              offsetX: 100,
              offsetY: 30,
              rotate: 0,
              formatter: function (val) {
                return val;
              },
            },
          },
          stroke: {
            width: 0.5,
          },
          fill: {
            type: "solid",
            opacity: 0.6,
          },

          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
        },
      },
      //PRESUPUESTO
      budgetData: {
        currentExpense: "",
        totalBudget: "",
        deviation: "",
        monetaryUnit: "",
      },
      chartExpense: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      //RIESGO
      riskData: [],
      riskByDimension: {
        series: [],
        options: {
          labels: [],
          legend: {
            position: "bottom",
          },
          chart: {
            height: 400,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "polarArea",
            zoom: { enabled: false },
            offsetY: 0,
            offsetX: 0,
          },
          stroke: {
            colors: ["#fff"],
          },
          fill: {
            opacity: 0.8,
          },
          yaxis: {
            max: 0,
          },
          // Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Verde Claro:'#16C62E' - Gris: '#7F7F7F'
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      riskResult: "",
      riskDimension: "",
      //OKR
      date: {
        start: "",
        end: "",
      },
      okrTitle: {
        title1: "",
        title2: "",
        title3: "",
      },
      chartOkr1: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligth",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      chartOkr2: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligth",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      chartOkr3: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          xaxis: {
            offsetX: 100,
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligthter",
              fontFamily: "Josefin Sans, Arial, sans-serif",
              color: "#263238",
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      //AGILIDAD
      chartAgility: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radar",
            height: 350,
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1,
            },
          },
          responsive: [
            {
              breakpoint: 680,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
            },
          ],
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          xaxis: {
            categories: ["Tiempo de Ciclo", "Velocidad", "Predictibilidad", "Eficiencia"],
            labels: {
              show: true,
              style: {
                colors: ["#7319D5", "#7319D5", "#7319D5", "#7319D5"],
                fontSize: 13,
              },
            },
          },
          stroke: {
            width: 2,
          },
          fill: {
            opacity: 0.1,
          },
          markers: {
            size: 0,
          },
          legend: {
            position: "bottom",
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      //SATISFACCION
      npsValue: 0,
      firstLine: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "pie",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 10,
            },
          },
          title: {
            text: undefined,
            align: "center",
            margin: 0,
            style: {
              fontSize: 16,
              fontWeight: "normal",
            },
          },
          xaxis: {
            categories: [""],
            min: 0,
            max: 100,
          },
          yaxis: [{}],
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
            x: {
              formatter: function () {
                return "Último mes registrado";
              },
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
              horizontal: 5,
              vertical: 4,
            },
          },
          dataLabels: {
            style: {
              fontSize: "14px",
              colors: ["#ffffff", "#ffffff", "#ffffff", "#404040", "#404040"],
            },
            dropShadow: {
              enabled: false,
            },
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
          colors: ["#7319D5", "#8F47DD", "#AB75E5", "#C7A3EE", "#E3D1F6"],
        },
      },
      //ENGAGEMENT
      chartAdherenceMethodologies: {
        series: [],
        options: {
          series: [],
          chart: {
            offsetY: 0,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            colors: ["#ffffff"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          stroke: { lineCap: "round" },
          labels: [""],
        },
      },
      chartTechnologyAdoption: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartCircleConversionRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartDigitalClient: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            offsetX: -20,
            type: "heatmap",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#7F7F7F", "#7319d5"].reverse(),
          xaxis: {
            type: "category",
            name: "Hola",
            categories: [],
          },
          title: {
            text: undefined,
          },
          grid: {
            padding: {
              right: 20,
            },
          },
        },
      },
      ltvSum: {
        thisMonthLTV: 0,
        lastMonthLTV: 0,
      },
      //DESEMPEÑO
      chartYield: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartDigitalSkillsImprovement: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      //RENTABILIDAD
      chartDigitizationProcess: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartOpimization: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
            fontFamily: "Josefin Sans, Arial, sans-serif",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      //EFICIENCIA
      chartCostReduction: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartDigitalSale: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      //PRODUCTIVIDAD
      chartInvestment: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "%",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartService: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      chartProcess: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      productivityData: {
        productivityServices: "",
        productivityProcess: "",
      },
    };
  },
  methods: {
    goPage() {},
    redirectPage(pageName) {
      const routes = [
        {
          page: "Ficha",
          route: {
            path: "/pmo-digital/ficha",
            name: "pmo-digital-ficha",
            component: () => import("@/views/pmo/Ficha"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Roadmap",
          route: {
            path: "/pmo-digital/planification",
            name: "pmo-digital-planification",
            component: () => import("@/views/pmo/Planification"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Presupuesto",
          route: {
            name: "pmo-digital-budget",
            path: "/pmo-digital/budget",
            component: () => import("@/views/pmo/Budget"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Riesgo",
          route: {
            name: "pmo-digital-risk",
            path: "/pmo-digital/risk",
            component: () => import("@/views/pmo/Risk"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "OKR",
          route: {
            name: "pmo-digital-objectives",
            path: "/pmo-digital/objectives",
            component: () => import("@/views/pmo/Objectives"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Agilidad",
          route: {
            name: "pmo-digital-organizational-agility",
            path: "/pmo-digital/organizational-agility",
            component: () => import("@/views/pmo/OrganizationalAgility"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Satisfacción",
          route: {
            name: "pmo-digital-kpibusiness",
            path: "/pmo-digital/kpibusiness",
            component: () => import("@/views/pmo/KPIBusiness"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Engagement",
          route: {
            name: "pmo-digital-engagement",
            path: "/pmo-digital/engagement",
            component: () => import("@/views/pmo/Engagement"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Desempeño",
          route: {
            name: "pmo-digital-performance",
            path: "/pmo-digital/performance",
            component: () => import("@/views/pmo/Performance"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Rentabilidad",
          route: {
            name: "pmo-digital-profitability",
            path: "/pmo-digital/profitability",
            component: () => import("@/views/pmo/Profitability"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Eficiencia",
          route: {
            name: "pmo-digital-efficiency",
            path: "/pmo-digital/efficiency",
            component: () => import("@/views/pmo/Efficiency"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
        {
          page: "Productividad",
          route: {
            name: "pmo-digital-productivity",
            path: "/pmo-digital/productivity",
            component: () => import("@/views/pmo/Productivity"),
            meta: {
              requiresAuth: false,
              layout: "app-layout",
            },
          },
        },
      ];
      routes.forEach((r) => {
        if (r.page == pageName) {
          this.$router.push({ name: r.route.name });
        }
      });
    },
    getData() {
      if (this.$route.name === "project-pmo" && this.$route.params && this.$route.params.projectId) {
        this.loadProject(this.$route.params.projectId);
      } else {
        sessionStorage.setItem("pmoProjectIds", JSON.stringify(this.projectIds));
        this.getSummary();
        this.getProductivityData();
        this.getBudgetData();
        this.getKpisData();
        this.getOkrData();
        this.getEngagementData();
        this.getPerformanceData();
        this.getRiskData();
        this.getProfitability();
        this.getEfficiencyData();
        this.getAgilityData();
        this.getProject();
      }
    },
    getProject() {
      projectService
        .getProject(this.projectIds)
        .then((data) => {
          this.projectFile = data[0];
          sessionStorage.setItem("pmoProject", JSON.stringify(this.projectFile));
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    loadProject(idProject) {
      //console.log(idProject);
      callForTenderService.getProject(idProject).then((project) => {
        if (project) {
          let p = {
            enterpriseId: project.clientId,
            projectId: project.id,
            name: project.name,
            projectCode: project.name,
            breach: project.summary,
            responsible: project.owner,
            dimension: project.dimension?.name,
            evalCriteria: project.evaluationCriteria?.name,
            estimatedBudget: project.estimatedBudget
              ? project.estimatedBudget.value + project.estimatedBudget.currency
              : null,
            businessObjective: project.businessGoal,
            reach: project.scope,
            projectObjective: project.proyectGoal,
            qualitativeImprov: project.qualitativeImprovements,
            quantitativeImprov: project.quantitativeImprovements,
            period: project.period ? project.period.name : null,
          };
          projectService.saveProject(p).then(() => {
            this.$router.push({
              name: "dashboard-pmo",
              params: { enterpriseId: p.enterpriseId, projectId: p.projectId },
            });
          });
        }
      });
    },
    getSummary() {
      PlanificationService.getChartSummary(this.projectIds)
        .then((data) => {
          this.chartSummary.series = data.series[0].data;
          this.summaryPlanification = data.data;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    getBudgetData() {
      BudgetService.getSummaryBudget(this.projectIds)
        .then((data) => {
          this.budgetData.currentExpense = data.resume[0].value;
          this.budgetData.totalBudget = data.resume[4].value;
          this.budgetData.deviation = data.resume[2].name;
          this.chartExpense.series = data.resume[0].data;
        })
        .catch((err) => console.dir(err));

      BudgetService.getTableCostCenter(this.projectIds)
        .then((data) => {
          this.budgetData.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => console.dir(err));
    },
    getProductivityData() {
      productivityService
        .getChartInvestment(this.projectIds)
        .then((data) => {
          this.chartInvestment.series = data.investment;
          this.$refs.chartInvestment.updateOptions({
            xaxis: {
              categories: data.investment[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));

      productivityService
        .getChartScalability(this.projectIds)
        .then((data) => {
          this.chartService.series = data.service.series;
          this.chartProcess.series = data.process.series;
        })
        .catch((err) => console.dir(err));
    },
    getEngagementData() {
      EngagementService.getAdherenceMethodologies(this.projectIds)
        .then((data) => {
          this.chartAdherenceMethodologies.series = data.series;
        })
        .catch((err) => console.dir(err));
      EngagementService.getTechnologyAdoption(this.projectIds)
        .then((data) => {
          this.chartTechnologyAdoption.series = data.series;
        })
        .catch((err) => console.dir(err));
      EngagementService.getCircleConversionRate(this.projectIds)
        .then((data) => {
          this.chartCircleConversionRate.series = data.series;
        })
        .catch((err) => console.dir(err));
      LtvService.getChartLtv(this.projectIds)
        .then((data) => {
          this.chartDigitalClient.series = data.serie;
        })
        .catch((err) => {
          console.dir(err);
        });
      LtvService.getLtvSum(this.projectIds)
        .then((data) => {
          this.ltvSum.thisMonthLTV = data.now;
          this.ltvSum.lastMonthLTV = data.last;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    getPerformanceData() {
      PerformanceService.getChartYield(this.projectIds)
        .then((data) => {
          this.chartYield.series = data.series;
        })
        .catch((err) => console.dir(err));
      PerformanceService.getChartTechAndSystemTraining(this.projectIds)
        .then((data) => {
          this.chartDigitalSkillsImprovement.series = data.skills.series;
        })
        .catch((err) => console.dir(err));
    },
    getRiskData() {
      RiskService.getTopRisk(this.projectIds)
        .then((data) => {
          this.riskData = data.mainRisks;
          let lowRisk = [];
          let mediumRisk = [];
          let highRisk = [];
          this.riskData.forEach((r) => {
            console.log("cat: ", r);
            if (r.priority >= 15) {
              highRisk.push("hr");
              this.riskDimension = r.category;
            } else if (r.priority > 6 && r.priority < 15) {
              mediumRisk.push("mr");
              this.riskDimension = r.category;
            } else if (r.priority <= 6) {
              lowRisk.push("lr");
              this.riskDimension = r.category;
            }
          });
          if (highRisk.length != 0) {
            this.riskResult = "hr";
          } else if (highRisk.length == 0 && mediumRisk.length != 0) {
            this.riskResult = "mr";
          } else if (highRisk.length == 0 && mediumRisk.length == 0) {
            this.riskResult = "lr";
          }
        })
        .catch((err) => console.dir(err));
    },
    getProfitability() {
      ProfitabilityService.getChartAnualProfit(this.projectIds)
        .then((data) => {
          this.chartDigitizationProcess.series = data.process.data;
          this.chartOpimization.series = data.opmization.data;
        })
        .catch((err) => console.dir(err));
    },
    getEfficiencyData() {
      EfficiencyService.getChartDigitalTools(this.projectIds)
        .then((data) => {
          this.chartCostReduction.series = data.cost;
          this.$refs.chartCostReduction.updateOptions({
            xaxis: {
              categories: data.cost[0].dates,
            },
            yaxis: [{ title: { text: this.budgetData.monetaryUnit } }],
          });
        })
        .catch((err) => console.dir(err));
      EfficiencyService.getChartDigitalChannels(this.projectIds)
        .then((data) => {
          this.chartDigitalSale.series = data.sale;
          this.$refs.chartDigitalSale.updateOptions({
            xaxis: {
              categories: data.sale[0].dates,
            },
          });
        })
        .catch((err) => console.dir(err));
    },
    getKpisData() {
      KpiBusinessService.getFirstLine(this.projectIds)
        .then((data) => {
          this.firstLine.series = data.series[0].data;
          this.firstLine.options.labels = data.labels;
        })
        .catch((err) => console.dir(err));

      KpiBusinessService.getSummaryKPINegocio(this.projectIds)
        .then((data) => {
          this.npsValue = data.resumen[0].value;
        })
        .catch((err) => console.dir(err));
    },
    getOkrData() {
      ObjectivesService.getChartDashboard(this.projectIds)
        .then((data) => {
          if (data.objective1) {
            this.chartOkr1.series = data.objective1.cdata.series;
            this.okrTitle.title1 = data.objective1.description;
          }
          if (data.objective2) {
            this.chartOkr2.series = data.objective2.cdata.series;
            this.okrTitle.title2 = data.objective2.description;
          }
          if (data.objective3) {
            this.chartOkr3.series = data.objective3.cdata.series;
            this.okrTitle.title3 = data.objective3.description;
          }
          if (data.objective1) {
            this.$refs.chartOkr1.updateOptions({
              labels: data.objective1.cdata.metaInformation,
            });
          }
          if (data.objective2) {
            this.$refs.chartOkr2.updateOptions({
              labels: data.objective2.cdata.metaInformation,
            });
          }
          if (data.objective3) {
            this.$refs.chartOkr3.updateOptions({
              labels: data.objective3.cdata.metaInformation,
            });
          }
          this.date.start = data.startDate;
          this.date.end = data.endDate;
        })
        .catch((err) => console.dir(err));
    },
    getAgilityData() {
      AgilityService.getAgilityDash(this.projectIds)
        .then((data) => {
          this.chartAgility.series = data.series;
          // this.$refs.chartAgility.updateOptions({
          //   xaxis: {
          //     categories: data.series[0].categories,
          //   },
          // });
        })
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.div-container {
  font-family: Josefin Sans, Arial, sans-serif;
}
.alert-pmo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: #16c62e;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Verde Manzana / Verde Manzana 4 */

  background: #c5f1cb;
  border-radius: 4px;
}
.chip-pmo {
  background: #dcc5f4;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: fit-content;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Tway / Violeta */

  color: #7319d5;
}
.text-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-container {
  display: flex;
  align-items: stretch;
}
.height-fix {
  height: 250px;
}
.fill-height {
  height: 100%;
}
.box-content {
  height: 90%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}
.box-container > .inside-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  height: inherit;
}
.box-container > .inside-box2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.inside-box > div {
  padding-top: 0px;
  margin-top: 0px;
}
.classification-color {
  border-radius: 70px;
  width: 15px;
  height: 15px;
  border: 1px;
}

.tway-header-1,
.grid-container {
  border-radius: 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.ficha-text {
  width: fit-content;
}
.ficha-text div {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px !important;
}
.ficha-text div span:first-child {
  width: 215px;
}

.text-veritical-align {
  position: relative;
  top: 45px;
  text-align: center;
}

@media screen and (max-width: 1635px) {
  .fix-text {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1636px) {
  .text-veritical-align {
    position: relative;
    top: 70px;
  }
}
</style>
