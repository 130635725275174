import uaaAxios from "@/utils/authAxios";
const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getChartIncidents: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getChartIncidents`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartDigitalTools: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getChartDigitalTools`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartAgileMethodology: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getChartAgileMethodology`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartDigitalChannels: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getChartDigitalChannels`, project)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartTimeEfficiency: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getChartTimeEfficiency`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  saveFixedData: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/saveEfficiencyFixed`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getFixedData: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getEfficiencyFixed`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveEfficiencyTrace: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/saveEfficiencyTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getEfficiencyTrace: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/getEfficiencyTrace`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteEfficiencyTrace: function (project, idEfficiency) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/efficiency/deleteEfficiency?idEfficiency=${idEfficiency}`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
};
