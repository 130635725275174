import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getSummaryRisk: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/chartSummaryRisk`, project)
        .then((response) => {
          resolve(response.data);
          console.log(response);
          //resolve(getSummaryRisk);
        })
        .catch((err) => reject(err));
    });
  },
  getRiskMatriz: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/chartRiskMatriz`, project)
        .then((response) => {
          resolve(response.data);
          console.log(response);
          //resolve(getRiskMatriz);
        })
        .catch((err) => reject(err));
    });
  },
  getRiskByDimension: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/chartRiskByDimension`, project)
        .then((response) => {
          resolve(response.data);
          console.log(response);
          //resolve(getRiskByDimension);
        })
        .catch((err) => reject(err));
    });
  },
  getTopRisk: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/chartTopRisk`, project)
        .then((response) => {
          resolve(response.data);
          console.log(response);
          //resolve(getTopRisk);
        })
        .catch((err) => reject(err));
    });
  },
  addCategories: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/addCategories`, json)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getCategories: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/getCategories`, json)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllRisk: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/allRisk`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addRiskTrace: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/risk/createRisk`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteRiskTrace: function (idRisk) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${apiURL}/risk/deleteRisk?id=${idRisk}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
