//import uaaAxios from "@/utils/authAxios";
import uaaAxios from "@/utils/authAxios";
const apiURL = process.env.VUE_APP_PMO_API;

const chartDigitalClient = {
  series: [
    {
      name: "Julio 2021",
      data: [
        { x: "0", y: 615 },
        { x: "1", y: 698 },
        { x: "2", y: 882 },
        { x: "3", y: 1182 },
        { x: "4", y: 1228 },
        { x: "5", y: 1347 },
        { x: "6", y: 1486 },
        { x: "7", y: 1742 },
        { x: "8", y: 1154 },
        { x: "9", y: 1154 },
        { x: "10", y: 1154 },
        { x: "11", y: 1254 },
        { x: "12", y: 1250 },
      ].reverse(),
    },
    {
      name: "Agosto 2021",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 1054 },
        { x: "7", y: 1054 },
        { x: "8", y: 1054 },
        { x: "9", y: 1054 },
        { x: "10", y: 1054 },
        { x: "11", y: 50 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Septiembre 2021",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 1054 },
        { x: "7", y: 1054 },
        { x: "8", y: 1054 },
        { x: "9", y: 1054 },
        { x: "10", y: 50 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Octubre 2021",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 1054 },
        { x: "7", y: 1054 },
        { x: "8", y: 1054 },
        { x: "9", y: 50 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Noviembre 2021",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 1054 },
        { x: "7", y: 1054 },
        { x: "8", y: 50 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Diciembre 2021",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 1054 },
        { x: "7", y: 50 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Enero 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 1054 },
        { x: "6", y: 50 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Febrero 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 1054 },
        { x: "5", y: 50 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Marzo 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 1054 },
        { x: "4", y: 50 },
        { x: "5", y: 0 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Abril 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 1054 },
        { x: "3", y: 50 },
        { x: "4", y: 0 },
        { x: "5", y: 0 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Mayo 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 1054 },
        { x: "2", y: 50 },
        { x: "3", y: 0 },
        { x: "4", y: 0 },
        { x: "5", y: 0 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Junio 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 50 },
        { x: "2", y: 0 },
        { x: "3", y: 0 },
        { x: "4", y: 0 },
        { x: "5", y: 0 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
    {
      name: "Julio 2022",
      data: [
        { x: "0", y: 1054 },
        { x: "1", y: 0 },
        { x: "2", y: 0 },
        { x: "3", y: 0 },
        { x: "4", y: 0 },
        { x: "5", y: 0 },
        { x: "6", y: 0 },
        { x: "7", y: 0 },
        { x: "8", y: 0 },
        { x: "9", y: 0 },
        { x: "10", y: 0 },
        { x: "11", y: 0 },
        { x: "12", y: 0 },
      ],
    },
  ].reverse(),
};

export default {
  getSummaryEngagement: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        // .post(`${apiURL}/agility/chartSummaryEngagement`)
        .post(`${apiURL}/agility/chartFlowEfficiency`)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getRetentionRate: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartRetentionRate`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          console.error(err);
          console.log(err.message);
        });
    });
  },
  getConversionRate: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartConversionRate`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getWinBackRate: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartWinBackRate`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getDigitalClient: function () {
    //const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartDigitalClient`)
        .then((response) => {
          console.log(response.data);
          //resolve(response.data);
          resolve(chartDigitalClient);
        })
        .catch((err) => reject(err));
    });
  },
  getAgileMethodologies: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios

        .post(`${apiURL}/engagement/chartAgileMethodologies`, project)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getAdherenceMethodologies: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartAdherenceMethodologies`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getTechnologyAdoption: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartTechnologyAdoption`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getCircleConversionRate: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/chartCircleConversionRate`, project)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getEngagements: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/getEngagements`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  updateEngagement: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/updateEngagement`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCustomerEngagements: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/getEngagementCustomer`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addCustomerEngagements: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/addEngagementCustomer`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteEngagementCustomer: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/deleteEngagementCustomer`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getIndexMax: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/engagement/getIndexMaxEngagementCustomer`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
