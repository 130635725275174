import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getSummaryObjectives: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/chartSummaryObjectives`, json)
        .then((response) => {
          resolve(response.data);
          //resolve(getSummaryObjectives);
        })
        .catch((err) => reject(err));
    });
  },
  chartCircleData: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/chartCircleData`, project)
        .then((response) => {
          resolve(response.data);
          //resolve(getCircleData);
        })
        .catch((err) => reject(err));
    });
  },
  addObjective: function (request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/createObjective`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getMetaUnit: function () {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${apiURL}/admin/getComboMetaUnit`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getOkrList: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/getOkr`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  getObjetiveInformation: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/getObjetive`, json)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteKeyResult: function (idKr) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${apiURL}/objectives/deleteKeyResult?idKr=${idKr}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  deleteInitiative: function (idKr, initName) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/deleteInitiative?idKr=${idKr}&nameInitiative=${initName}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  getObjetive: function (project, idObjetive) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/getObjetive?idObjective=${idObjetive}`, json)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  saveObjective: function (request) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/saveObjective`, request)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  getChartDashboard: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/getChartDashboard`, project)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  getChartDashboardSummary: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/objectives/getChartDashboardSummary`, project)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
};
